/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSession } from 'next-auth/react';

export default function useUserPreference(key, defaultValue) {
  const { data: session } = useSession();
  const [preference, setPreference] = useState(defaultValue);

  const [createUserPreference] = useMutation(CREATE_USER_PREFERENCE);
  const [updateUserPreference] = useMutation(UPDATE_USER_PREFERENCE);
  const { data, refetch, loading } = useQuery(FETCH_USER_PREFERENCE, {
    variables: {
      filter: { user: { id: { eq: session?.user.id } } },
    },
  });

  const userPreference = data?.userPreferences.data?.[0];

  const handleCreateUserPreference = useCallback(async (value) => {
    await createUserPreference({
      variables: {
        data: {
          user: session?.user.id,
          preferences: { [key]: value },
        },
      },
    });
    await refetch();
  }, [key, userPreference]);

  const handleUpdateUserPreference = useCallback(async (value) => {
    await updateUserPreference({
      variables: {
        id: userPreference.id,
        data: {
          preferences: {
            ...userPreference.attributes.preferences,
            [key]: value,
          },
        },
      },
    });
    await refetch();
  }, [key, userPreference]);

  useEffect(() => {
    if (!loading && userPreference) {
      setPreference(userPreference.attributes.preferences[key]);
    }
    if (!loading && !userPreference) {
      handleCreateUserPreference(preference).then(() => {
        setPreference(preference);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && userPreference) {
      if (userPreference.attributes.preferences[key] !== preference) {
        handleUpdateUserPreference(preference);
      }
    }
  }, [preference]);

  return [preference, setPreference];
}

const CREATE_USER_PREFERENCE = gql`
mutation CreateUserPreference($data:UserPreferenceInput!) {
  createUserPreference(data: $data) {
    data {
      id
    }
  }
}`;

const UPDATE_USER_PREFERENCE = gql`
mutation UpdateUserPreference($id: ID!, $data: UserPreferenceInput!) { 
  updateUserPreference(id: $id, data: $data) {
    data {
      id
    }
  }
}`;

const FETCH_USER_PREFERENCE = gql`
query Fetch($filter: UserPreferenceFiltersInput) {
  userPreferences(filters: $filter) {
    data {
      id
      attributes {
        preferences
      }
    }
  }
}`;
