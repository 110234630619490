import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { FiLogOut, FiSettings, FiUser } from 'react-icons/fi';
import { signOut, useSession } from 'next-auth/react';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { selectCurrentTenant } from '@slices/tenant';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function AccountPopover(props) {
  const {
    anchorEl, onClose, open, ...other
  } = props;
  const router = useRouter();
  const { i18n: { t } } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: session } = useSession();
  const tenant = useSelector(selectCurrentTenant);

  const { user } = session;

  const handleLogout = async () => {
    try {
      onClose?.();
      await signOut();
      router.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('components.AccountPopover.logoutError'), {
        variant: 'error',
      });
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          src={user.profileImage?.formats?.small?.url ?? user.profileImage?.url}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <FiUser />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {tenant.attributes.name}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <NextLink
          href="/account"
          legacyBehavior
          passHref
        >
          <MenuItem component="a">
            <ListItemIcon>
              <FiSettings />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  {t('components.AccountPopover.settings')}
                </Typography>
              )}
            />
          </MenuItem>
        </NextLink>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FiLogOut />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                {t('components.AccountPopover.logout')}
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

AccountPopover.defaultProps = {
  anchorEl: null,
  onClose: null,
  open: false,
};
