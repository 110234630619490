import {
  Box, ListItemIcon, ListItemText, MenuItem, Popover, Typography,
} from '@mui/material';
import { languages } from '@config';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useUserPreference from '@lib/hooks/useUserPreference';
import { useEffect } from 'react';

export default function LanguagePopover(props) {
  const {
    anchorEl, onClose, open, ...other
  } = props;
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [userLocale, setUserLocale] = useUserPreference('locale', 'de');

  useEffect(() => {
    i18n.changeLanguage(userLocale);
  }, [i18n, userLocale]);

  const handleChange = (language) => {
    onClose?.();
    setUserLocale(language);
    const lang = languages.find((l) => l.lang === language);
    i18n.changeLanguage(language);
    enqueueSnackbar(
      t('components.LanguagePopover.notifications.languageChanged', {
        lang: lang.label,
      }),
      {
        variant: 'success',
      },
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 240 } }}
      transitionDuration={0}
      {...other}
    >
      {languages.map((item) => (
        <MenuItem
          key={item.lang}
          onClick={() => handleChange(item.lang)}
          sx={{ py: 1 }}
        >
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                height: 20,
                width: 20,
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img
                alt={item.label}
                src={item.icon}
              />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="subtitle2">
                {item.label}
              </Typography>
            )}
          />
        </MenuItem>
      ))}
    </Popover>
  );
}

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

LanguagePopover.defaultProps = {
  anchorEl: null,
  onClose: null,
  open: false,
};
