import { Box, Hidden, Typography } from '@mui/material';
import { useEffect } from 'react';
import Router from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavbarTitle, setTitle } from '@slices/navbar-title';

export default function NavbarTitle() {
  const dispatch = useDispatch();
  const { title, subtitle } = useSelector(selectNavbarTitle);

  useEffect(() => {
    const handler = () => {
      dispatch(setTitle({ title: '', subtitle: '' }));
    };

    Router.events.on('routeChangeStart', handler);

    return () => {
      Router.events.off('routeChangeStart', handler);
    };
  }, [dispatch]);

  return (
    <Box sx={{ flexGrow: 1, ml: 1 }}>
      <Hidden mdDown>
        <Typography
          color="text.primary"
          component="div"
          sx={SX}
          variant="subtitle2"
        >
          {title}
        </Typography>
        <Typography
          color="text.primary"
          component="div"
          sx={SX}
          variant="caption"
        >
          {subtitle}
        </Typography>
      </Hidden>
    </Box>
  );
}

const SX = {
  lineHeight: '1.2em',
};
